// Fonts
$font-stack: 'Assistant', 'sans-serif';
$font-stack-title: 'Source Sans Pro', 'sans-serif';

// Colours
$primary-color: #cb2026;
$primary-color-light: #f52f35;
$primary-color-dark: #4a0c0e;
$secondary-color: #818181;
$secondary-color-light: #cccccc;
$secondary-color-dark: #4a4a4a;
$black-colour: #222222;
$white-colour: #fdfdfd;