html {
  font-size: 14px;
}
#csv-reader-temp {
  font: inherit;
  width: 100%;
  border: 0;
  height: 1.1875em;
  margin: 0;
  padding: 6px 7px;
  display: block;
  min-width: 0;
  box-sizing: content-box;
  background: none;
  -webkit-tap-highlight-color: transparent; }

#tinymce-editor {
  margin-top: 5vh;
  height: 80vh; }

.empty-accordion {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  color: #cccccc; }

.accordion {
  min-width: 50%;
  height: 100%;
  max-height: 475px;
  overflow-y: scroll;
  overflow-x: scroll;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px; }
  .accordion .accordion__item + .accordion__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1); }
  .accordion .accordion__button {
    background-color: #f4f4f4;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none; }
  .accordion .accordion__button:hover {
    background-color: #ddd; }
  .accordion .accordion__button:before {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .accordion .accordion__button[aria-expanded='true']::before,
  .accordion .accordion__button[aria-selected='true']::before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .accordion .accordion__panel {
    padding: 20px;
    -webkit-animation: fadein 0.35s ease-in;
            animation: fadein 0.35s ease-in; }

@-webkit-keyframes fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadein {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
  .accordion .outter-table {
    text-align: left;
    width: 100%; }
    .accordion .outter-table .email-container {
      width: 50%;
      vertical-align: top; }
    .accordion .outter-table .innter-tocc-table .innter-table-data,
    .accordion .outter-table .innter-bcc-table .innter-table-data {
      padding-bottom: 5px; }

.error {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }

*::-webkit-scrollbar {
  display: none; }

* {
  scroll-behavior: smooth; }

body {
  margin: 0;
  padding: 0;
  font-family: "Assistant", "sans-serif";
  background-color: #fdfdfd;
  min-height: 100vh;
  display: flex;
  flex-direction: column; }

h1 {
  font-family: "Source Sans Pro", "sans-serif";
  font-size: 1.7rem;
  font-weight: 600;
  color: #4a4a4a;
  line-height: 4rem; }

h2 {
  font-family: "Source Sans Pro", "sans-serif";
  font-size: 1.5rem;
  font-weight: 600;
  color: #4a4a4a;
  line-height: 3rem; }

h3 {
  font-family: "Source Sans Pro", "sans-serif";
  font-size: 1.3rem;
  font-weight: 600;
  color: #4a4a4a;
  line-height: 2rem; }

h4 {
  font-family: "Source Sans Pro", "sans-serif";
  font-size: 1.1rem;
  font-weight: 600;
  color: #4a4a4a;
  line-height: 1rem; }

p {
  font-family: "Assistant", "sans-serif";
  font-size: 0.875em;
  font-weight: 400;
  color: #4a4a4a; }

.note-level-one {
  font-family: "Assistant", "sans-serif";
  font-size: 0.75em;
  font-weight: 300;
  color: #4a4a4a; }

.note-level-two {
  font-family: "Assistant", "sans-serif";
  font-size: 0.75em;
  font-weight: 100;
  color: #4a4a4a; }

.page-container {
  padding: 15vh 15vw;
  color: #222222;
  max-width: 100vw;
  min-height: 70vh; }

.alert {
  margin: 10px; }

