@import "./scss/variables.scss";
@import "./scss/Mail.scss";
@import "./scss/CSVReviewList.scss";
@import "./scss/ErrorNotFound.scss";

*::-webkit-scrollbar {
  display: none;
}

* {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: $font-stack;
  background-color: $white-colour;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

h1 {
  font-family: $font-stack-title;
  font-size: 1.7rem;
  font-weight: 600;
  color: $secondary-color-dark;
  line-height: 4rem;
}

h2 {
  font-family: $font-stack-title;
  font-size: 1.5rem;
  font-weight: 600;
  color: $secondary-color-dark;
  line-height: 3rem;
}

h3 {
  font-family: $font-stack-title;
  font-size: 1.3rem;
  font-weight: 600;
  color: $secondary-color-dark;
  line-height: 2rem;
}

h4 {
  font-family: $font-stack-title;
  font-size: 1.1rem;
  font-weight: 600;
  color: $secondary-color-dark;
  line-height: 1rem;
}

p {
  font-family: $font-stack;
  font-size: 0.875em;
  font-weight: 400;
  color: $secondary-color-dark;
}

.note-level-one {
  font-family: $font-stack;
  font-size: 0.75em;
  font-weight: 300;
  color: $secondary-color-dark;
}

.note-level-two {
  font-family: $font-stack;
  font-size: 0.75em;
  font-weight: 100;
  color: $secondary-color-dark;
}

.page-container {
  padding: 15vh 15vw;
  color: $black-colour;
  max-width: 100vw;
  min-height: 70vh;
}

.alert {
  margin: 10px;
}
