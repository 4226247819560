#csv-reader-temp {
  font: inherit;
  width: 100%;
  border: 0;
  height: 1.1875em;
  margin: 0;
  padding: 6px 7px;
  display: block;
  min-width: 0;
  box-sizing: content-box;
  background: none;
  -webkit-tap-highlight-color: transparent;
}

#tinymce-editor {
  margin-top: 5vh;
  height: 80vh;
}
