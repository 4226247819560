.empty-accordion {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  color: $secondary-color-light;
}

.accordion {
  min-width: 50%;
  height: 100%;
  max-height: 475px;
  overflow-y: scroll;
  overflow-x: scroll;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  .accordion__item + .accordion__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .accordion__button {
    background-color: #f4f4f4;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
  }

  .accordion__button:hover {
    background-color: #ddd;
  }

  .accordion__button:before {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(-45deg);
  }

  .accordion__button[aria-expanded='true']::before,
  .accordion__button[aria-selected='true']::before {
    transform: rotate(45deg);
  }

  .accordion__panel {
    padding: 20px;
    animation: fadein 0.35s ease-in;
  }

  @keyframes fadein {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .outter-table {
    text-align: left;
    width: 100%;

    .email-container {
      width: 50%;
      vertical-align: top;
    }

    .innter-tocc-table,
    .innter-bcc-table {
      .innter-table-data {
        padding-bottom: 5px;
      }
    }
  }
}
